import { ReactNode } from 'react';
import { FormProvider as ReactHookFormProvider, UseFormReturn } from 'react-hook-form';
import { Box, SxProps } from '@mui/material';

interface FormProviderProps {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  onBlur?: VoidFunction;
  sx?: SxProps;
}

export function FormProvider({ children, methods, onSubmit, onBlur, sx }: FormProviderProps) {
  return (
    <ReactHookFormProvider {...methods}>
      <Box component="form" onSubmit={onSubmit} onBlur={onBlur} sx={sx}>
        {children}
      </Box>
    </ReactHookFormProvider>
  );
}
